const config = {
    //bot_url: "http://t.me/sit_safe_bot",
    //endpoint: "https://safety-platform-backend.sit.ourcodelab.com",
    //local_url: "https://safety-platform.sit.ourcodelab.com",
    bot_url: "https://t.me/sit_safety_test_bot",
    endpoint: "https://safety-platform-backend-staging.sit.ourcodelab.com",
    local_url: "https://safety-platform-staging.sit.ourcodelab.com",

    version: "1.3.16 (290524)",
    TELEGRAM_ADMIN_GROUP_ID: "-1001768257341",
    developer: "OurCodeLab LLP",
    website: "https://ourcodelab.com"
}

export default config;
